import React from "react";

import Image from "../../components/common/image";

// Import Images

// Order ID #51240
import pro25 from "../images/electronics/product/25.jpg";
import pro13 from "../images/electronics/product/13.jpg";
import pro16 from "../images/electronics/product/16.jpg";

// Order ID #51241
import pro12 from "../images/electronics/product/12.jpg";
import pro3 from "../images/electronics/product/3.jpg";

import pro14 from "../images/electronics/product/14.jpg";

import pro6 from "../images/electronics/product/6.jpg";
import furniture8 from "../images/furniture/8.jpg";

import jwel18 from "../images/jewellery/pro/18.jpg";
import fashion06 from "../images/fashion/pro/06.jpg";

import pro19 from "../images/electronics/product/19.jpg";
import pro20 from "../images/electronics/product/20.jpg";
import pro23 from "../images/electronics/product/23.jpg";
import pro24 from "../images/electronics/product/24.jpg";
import pro21 from "../images/electronics/product/21.jpg";
import pro8 from "../images/electronics/product/8.jpg";
import pro18 from "../images/electronics/product/18.jpg";
import pro17 from "../images/electronics/product/17.jpg";
import fashion19 from "../images/fashion/product/19.jpg";
import fashion14 from "../images/fashion/product/14.jpg";

import furniture6 from "../images/furniture/6.jpg";
import furniture4 from "../images/furniture/product/4.jpg";
import furniture7 from "../images/furniture/7.jpg";
import furniture16 from "../images/furniture/product/16.jpg";

const data = [
	{
		Order_Id: "3010",
		Barcode_Id: "#3010",
		Product_Id: "2530, 4360",
		// Resim: <Image id="image1" data={[pro25, pro13, pro16]} />,
		// Durum: <span className="badge badge-secondary">Cash On Delivery</span>,
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-warning">Getting Ready</span>,
		Order_Date: "12.06.2024",
		Total_Amount: "200$",
	},
	{
		Order_Id: "3009",
		Barcode_Id: "#3009",
		Product_Id: "1752",
		// Resim: <Image id="image1" data={[pro25, pro13, pro16]} />,
		// Durum: <span className="badge badge-secondary">Cash On Delivery</span>,
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-warning">Getting Ready</span>,
		Order_Date: "10.06.2024",
		Total_Amount: "500$",
	},
	{
		Order_Id: "3008",
		Barcode_Id: "#3008",
		Product_Id: "3400",
		// Resim: <Image id="image1" data={[pro25, pro13, pro16]} />,
		// Durum: <span className="badge badge-secondary">Cash On Delivery</span>,
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-warning">Getting Ready</span>,
		Order_Date: "07.06.2024",
		Total_Amount: "150$",
	},
	{
		Order_Id: "3007",
		Barcode_Id: "#3007",
		Product_Id: "600",
		// Resim: <Image id="image1" data={[pro25, pro13, pro16]} />,
		// Durum: <span className="badge badge-secondary">Cash On Delivery</span>,
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-warning">Getting Ready</span>,
		Order_Date: "05.06.2024",
		Total_Amount: "20$",
	},
	{
		Order_Id: "3006",
		Barcode_Id: "#3006",
		Product_Id: "2000",
		// Resim: <Image id="image1" data={[pro25, pro13, pro16]} />,
		// Durum: <span className="badge badge-secondary">Cash On Delivery</span>,
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-warning">Getting Ready</span>,
		Order_Date: "12.06.2024",
		Total_Amount: "20$",
	},
	{
		Order_Id: "3005",
		Barcode_Id: "#3005",
		Product_Id: "1406, 5110",
		// Resim: <Image id="image1" data={[pro25, pro13, pro16]} />,
		// Durum: <span className="badge badge-secondary">Cash On Delivery</span>,
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-danger">Shipped</span>,
		Order_Date: "12.05.2024",
		Total_Amount: "40$",
	},
	{
		Order_Id: "3004",
		Barcode_Id: "#3004",
		Product_Id: "5110",
		// Resim: <Image id="image1" data={[pro25, pro13, pro16]} />,
		// Durum: <span className="badge badge-secondary">Cash On Delivery</span>,
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-danger">Shipped</span>,
		Order_Date: "02.05.2024",
		Total_Amount: "77$",
	},
	{
		Order_Id: "3003",
		Barcode_Id: "#3003",
		Product_Id: "3257",
		// Durum: <span className="badge badge-secondary">Cash On Delivery</span>,
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-danger">Shipped</span>,
		Order_Date: "22.04.2024",
		Total_Amount: "234$",
	},
	{
		Order_Id: "3002",
		Barcode_Id: "#3002",
		Product_Id: "850",
		// Durum: <span className="badge badge-secondary">Cash On Delivery</span>,
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-danger">Shipped</span>,
		Order_Date: "13.04.2024",
		Total_Amount: "18$",
	},
	{
		Order_Id: "3001",
		Barcode_Id: "#3001",
		Product_Id: "10",
		// Durum: <span className="badge badge-secondary">Cash On Delivery</span>,
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-danger">Shipped</span>,
		Order_Date: "03.04.2024",
		Total_Amount: "68$",
	},
	{
		Order_Id: "3000",
		Barcode_Id: "#3000",
		Product_Id: "58",
		// Durum: <span className="badge badge-secondary">Cash On Delivery</span>,
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-danger">Shipped</span>,
		Order_Date: "03.03.2024",
		Total_Amount: "48$",
	},
	{
		Order_Id: "2999",
		Barcode_Id: "#2999",
		Product_Id: "978",
		// Durum: <span className="badge badge-secondary">Cash On Delivery</span>,
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-success">Delivered</span>,
		Order_Date: "03.04.2024",
		Total_Amount: "5$",
	},
	{
		Order_Id: "2998",
		Barcode_Id: "#2998",
		Product_Id: "520",
		// Durum: <span className="badge badge-secondary">Cash On Delivery</span>,
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-success">Delivered</span>,
		Order_Date: "18.04.2024",
		Total_Amount: "21$",
	},
];

export default data;
