
import {
	Search
} from "react-feather";
export const data = [
    {
        Order_Id: "3010",
		Barcode_Id: "#3010",
		Product_Id: "2530, 4360",
        Customer_Id: "15",
        Amount_of_Product: "2",
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-warning">Getting Ready</span>,
		Order_Date: "12.06.2024",
        Order_Address: "Istanbul/Turkey",
		Total_Amount: "200$",
        Bill_No: "21",
    },
    {
        Order_Id: "3009",
		Barcode_Id: "#3010",
		Product_Id: "1752",
        Customer_Id: "14",
        Amount_of_Product: "1",
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-warning">Getting Ready</span>,
		Order_Date: "10.06.2024",
        Order_Address: "Istanbul/Turkey",
		Total_Amount: "500$",
        Bill_No: "20",
    },
    {
        Order_Id: "3008",
		Barcode_Id: "#3008",
		Product_Id: "3400",
        Customer_Id: "13",
        Amount_of_Product: "1",
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-warning">Getting Ready</span>,
		Order_Date: "07.06.2024",
        Order_Address: "Istanbul/Turkey",
		Total_Amount: "150$",
        Bill_No: "19",
    },
    {
        Order_Id: "3007",
		Barcode_Id: "#3007",
		Product_Id: "600",
        Customer_Id: "12",
        Amount_of_Product: "1",
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-warning">Getting Ready</span>,
        Order_Date: "05.06.2024",
        Order_Address: "Istanbul/Turkey",
        Total_Amount: "20$",        
        Bill_No: "18",
    },
    {
        Order_Id: "3006",
		Barcode_Id: "#3006",
		Product_Id: "2000",
        Customer_Id: "11",
        Amount_of_Product: "1",
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-warning">Getting Ready</span>,
		Order_Date: "12.06.2024",
        Order_Address: "Istanbul/Turkey",
		Total_Amount: "20$",
        Bill_No: "17",
    },
    {
        Order_Id: "3005",
		Barcode_Id: "#3005",
		Product_Id: "1406, 5110",
        Customer_Id: "10",
        Amount_of_Product: "1",
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-warning">Getting Ready</span>,
		Order_Date: "12.05.2024",
        Order_Address: "Istanbul/Turkey",
		Total_Amount: "40$",
        Bill_No: "16",
    },
    {
        Order_Id: "3004",
		Barcode_Id: "#3004",
		Product_Id: "5110",
        Customer_Id: "9",
        Amount_of_Product: "1",
		Payment_Type: "Visa",
		Order_Status: <span className="badge badge-warning">Getting Ready</span>,
		Order_Date: "02.05.2024",
        Order_Address: "Istanbul/Turkey",
		Total_Amount: "77$",
        Bill_No: "15",
    },
]

export default data;

