export const data = [
    {
        Ürün_Id: "1",
        Ürün_Linki: "USASTCITY-6*",
        Ürün_Bedeli: "USASTCITY-6*",
        Kargo: "10%",
        Lojistik: "15.24",
        Vergi: "15.24",
        İade_Bedeli: "15.24",
        Diğer_Giderler: "15.24",
        Kâr: "5.24",
        Zarar: "-5.24",
    },
    {
        Ürün_Id: "2",
        Ürün_Linki: "USASTCITY-6*",
        Ürün_Bedeli: "USASTCITY-6*",
        Kargo: "10%",
        Lojistik: "15.24",
        Vergi: "15.24",
        İade_Bedeli: "15.24",
        Diğer_Giderler: "15.24",
        Kâr: "5.24",
        Zarar: "-5.24",
    },
    {
        Ürün_Id: "3",
        Ürün_Linki: "USASTCITY-6*",
        Ürün_Bedeli: "USASTCITY-6*",
        Kargo: "10%",
        Lojistik: "15.24",
        Vergi: "15.24",
        İade_Bedeli: "15.24",
        Diğer_Giderler: "15.24",
        Kâr: "5.24",
        Zarar: "-5.24",
    },
    {
        Ürün_Id: "4",
        Ürün_Linki: "USASTCITY-6*",
        Ürün_Bedeli: "USASTCITY-6*",
        Kargo: "10%",
        Lojistik: "15.24",
        Vergi: "15.24",
        İade_Bedeli: "15.24",
        Diğer_Giderler: "15.24",
        Kâr: "5.24",
        Zarar: "-5.24",
    },
   
]

export default data

  