const data = [
    {
        Kargo_Id: "14783112",
        Kargo_Firması: "Gray Brody",
        Teslim_Tarihi: "Nov 20, 2022",
        Toplam_Tutar:"$745",
    },
    {
        Kargo_Id: "14783112",
        Kargo_Firması: "Gray Brody",
        Teslim_Tarihi: "Nov 20, 2022",
        Toplam_Tutar:"$745",
    },
    { 
        Kargo_Id: "14783112",
    Kargo_Firması: "Gray Brody",
    Teslim_Tarihi: "Nov 20, 2022",
    Toplam_Tutar:"$745",
    },
    {
        Kargo_Id: "14783112",
        Kargo_Firması: "Gray Brody",
        Teslim_Tarihi: "Nov 20, 2022",
        Toplam_Tutar:"$745",
    },
    {
        Kargo_Id: "14783112",
        Kargo_Firması: "Gray Brody",
        Teslim_Tarihi: "Nov 20, 2022",
        Toplam_Tutar:"$745",
    },
    {
        Kargo_Id: "14783112",
        Kargo_Firması: "Gray Brody",
        Teslim_Tarihi: "Nov 20, 2022",
        Toplam_Tutar:"$745",
    },
    {
        Kargo_Id: "14783112",
        Kargo_Firması: "Gray Brody",
        Teslim_Tarihi: "Nov 20, 2022",
        Toplam_Tutar:"$745",
    },
    {
        Kargo_Id: "14783112",
        Kargo_Firması: "Gray Brody",
        Teslim_Tarihi: "Nov 20, 2022",
        Toplam_Tutar:"$745",
    },
    {
        Kargo_Id: "14783112",
        Kargo_Firması: "Gray Brody",
        Teslim_Tarihi: "Nov 20, 2022",
        Toplam_Tutar:"$745",
    },
    {
        Kargo_Id: "14783112",
        Kargo_Firması: "Gray Brody",
        Teslim_Tarihi: "Nov 20, 2022",
        Toplam_Tutar:"$745",
    },
    {
        Kargo_Id: "14783112",
        Kargo_Firması: "Gray Brody",
        Teslim_Tarihi: "Nov 20, 2022",
        Toplam_Tutar:"$745",
    },
    {
        Kargo_Id: "14783112",
        Kargo_Firması: "Gray Brody",
        Teslim_Tarihi: "Nov 20, 2022",
        Toplam_Tutar:"$745",
    }
]

export default data;

