import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../components/auth/login";
import LayoutRoutes from "./LayoutRoutes";
import { AuthProvider } from "../components/auth/AuthContext";
import ProtectedRoute from "../components/auth/ProtectedRoute";

const Routers = () => {
    return (
        <AuthProvider>
            <Fragment>
                <Routes>
                    <Route exact path="/" element={<Login />} />
                    <Route exact path="/auth/login" element={<Login />} />
                    <Route path="/*" element={<ProtectedRoute element={<LayoutRoutes />} />} />
                </Routes>
            </Fragment>
        </AuthProvider>
    );
};

export default Routers;
