import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import axios from 'axios';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import TextField from '@mui/material/TextField';

const Category = () => {
  const URL = "https://novahorizon1.xyz/api/v1";
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  const colorOptions = [
    { value: 'Altın', label: 'Altın' },
    { value: 'Bej', label: 'Bej' },
    { value: 'Beyaz', label: 'Beyaz' },
    { value: 'Bordo', label: 'Bordo' },
    { value: 'Ekru', label: 'Ekru' },
    { value: 'Gri', label: 'Gri' },
    { value: 'Gümüş', label: 'Gümüş' },
    { value: 'Haki', label: 'Haki' },
    { value: 'Kahverengi', label: 'Kahverengi' },
    { value: 'Kırmızı', label: 'Kırmızı' },
    { value: 'Lacivert', label: 'Lacivert' },
    { value: 'Mavi', label: 'Mavi' },
    { value: 'Metalik', label: 'Metalik' },
    { value: 'Mor', label: 'Mor' },
    { value: 'Pembe', label: 'Pembe' },
    { value: 'Sarı', label: 'Sarı' },
    { value: 'Siyah', label: 'Siyah' },
    { value: 'Turkuaz', label: 'Turkuaz' },
    { value: 'Turuncu', label: 'Turuncu' },
    { value: 'Yeşil', label: 'Yeşil' },
    { value: 'Çok Renkli', label: 'Çok Renkli' },
  ];

  const sizeOptions = [
    { value: '3XS', label: '3XS' },
    { value: '2XS', label: '2XS' },
    { value: 'XXS', label: 'XXS' },
    { value: 'XS', label: 'XS' },
    { value: 'S', label: 'S' },
    { value: 'M', label: 'M' },
    { value: 'L', label: 'L' },
    { value: 'XL', label: 'XL' },
    { value: '2XL', label: '2XL' },
    { value: 'XXL', label: 'XXL' },
    { value: '3XL', label: '3XL' },
    { value: 'XXXL', label: 'XXXL' },
    { value: '4XL', label: '4XL' },
    { value: '5XL', label: '5XL' },
    { value: '6XL', label: '6XL' },
    { value: '7XL', label: '7XL' },
    { value: '8XL', label: '8XL' },
    { value: '9XL', label: '9XL' },
    { value: '10XL', label: '10XL' },
    { value: '26', label: '26' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' },
    { value: '32', label: '32' },
    { value: '33', label: '33' },
    { value: '34', label: '34' },
    { value: '36', label: '36' },
    { value: '37', label: '37' },
    { value: '38', label: '38' },
    { value: '39', label: '39' },
    { value: '40', label: '40' },
    { value: '41', label: '41' },
    { value: '42', label: '42' },
    { value: '43', label: '43' },
    { value: '44', label: '44' },
    { value: '45', label: '45' },
    { value: '46', label: '46' },
    { value: '51', label: '51' },
    { value: '52', label: '52' },
    { value: '54', label: '54' },
    { value: '56', label: '56' },
    { value: '62', label: '62' },
    { value: '64', label: '64' },
    { value: '66', label: '66' },
    { value: '68', label: '68' },
    { value: '70', label: '70' },
    { value: '72', label: '72' },
  ];

  const generateToken = async () => {
    const url = `${URL}/generate-token`;
    const payload = {
      email: 'taner.akdemir@algebransoft.com',
      password: 'Abcde123*',
    };

    const response = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200 && response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Token could not be retrieved');
    }
  };

  const fetchProducts = async (token, categoryId = 83, selectedColor, selectedSize, selectedBrand) => {
    try {
      const colorFilter = selectedColor ? `&color=${selectedColor.value}` : '';
      const url = `${URL}/product?is_active=true&category_id=${categoryId}${colorFilter}&per_page=100`;
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-token': token
        },
      });

      if (response.status === 200 && response.data.success) {
        let productsData = response.data.data.map(product => {
          const attributes = product.attributes || [];
          const images = product.images || [];

          const colorAttribute = attributes.find(attr => attr.key === 'Renk');
          const color = colorAttribute ? colorAttribute.value : '-';
          const firstImage = images.length > 0 ? images[0].image : '';

          return {
            id: product.id,
            name: product.name,
            platform: product.platform,
            brandName: product.platformBrandName,
            price: product.productVariant.length > 0 ? product.productVariant[0].price : 'N/A',
            productUrl: product.platformUrl,
            size: product.productVariant.map(variant => variant.variantValue).join(', ') || '-',
            color: color,
            image: firstImage,
            productVariants: product.productVariant,
          };
        });

        // Apply brand filter if selectedBrand is set
        if (selectedBrand) {
          productsData = productsData.filter(product => product.brandName === selectedBrand.value);
        }

        const filteredProducts = selectedSize
          ? productsData.filter(product =>
              product.productVariants.some(variant => variant.variantValue === selectedSize.value)
            )
          : productsData;

        const newBrandOptions = filteredProducts.reduce((acc, product) => {
          if (!acc.some(opt => opt.value === product.brandName)) {
            acc.push({ value: product.brandName, label: product.brandName });
          }
          return acc;
        }, []);

        setBrandOptions(newBrandOptions);
        return filteredProducts;
      } else {
        throw new Error('Products could not be fetched');
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      return [];
    }
  };

  const fetchCategories = async (token) => {
    try {
      const url = `${URL}/category/a51dfc6e-9706-11ee-b6c4-02120a000912/subcategory?page=1`;
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-token': token
        },
      });

      if (response.status === 200 && response.data.success) {
        const categoriesData = response.data.data.map(category => ({
          value: category.id,
          label: category.name,
        }));

        setCategoryOptions(categoriesData);
      } else {
        throw new Error('Categories could not be fetched');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const applyFilters = () => {
    let filtered = [...products];

    if (minPrice && maxPrice) {
      filtered = filtered.filter(product => {
        const price = parseFloat(product.price);
        return price >= parseFloat(minPrice) && price <= parseFloat(maxPrice);
      });
    }

    if (selectedColor) {
      filtered = filtered.filter(product => product.color === selectedColor.value);
    }

    if (selectedSize) {
      filtered = filtered.filter(product =>
        product.productVariants.some(variant => variant.variantValue === selectedSize.value)
      );
    }

    if (selectedBrand) {
      filtered = filtered.filter(product => product.brandName === selectedBrand.value);
    }

    setFilteredProducts(filtered);
    setPage(1);  // Reset to first page
  };

  useEffect(() => {
    generateToken()
      .then(token => {
        fetchProducts(token, selectedSubCategory?.value || selectedCategory?.value || 83, selectedColor, selectedSize, selectedBrand)
          .then(productsData => {
            setProducts(productsData);
            setLoading(false);
          });
        fetchCategories(token);
      })
      .catch(error => console.error('Token could not be retrieved:', error));
  }, [selectedCategory, selectedSubCategory, selectedColor, selectedSize, selectedBrand]);

  useEffect(() => {
    applyFilters();
  }, [products, minPrice, maxPrice, selectedColor, selectedSize, selectedBrand]);

  const fetchSubCategories = async (category) => {
    const token = await generateToken();
    try {
      const url = `${URL}/category/a51dfc6e-9706-11ee-b6c4-02120a000912/subcategory?page=1`;
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-token': token
        },
      });

      if (response.status === 200 && response.data.success) {
        const subCategories = response.data.data.find(cat => cat.id === category.value).subCategories;
        const subCategoriesData = subCategories.map(subCategory => ({
          value: subCategory.id,
          label: subCategory.name,
        }));

        setSubCategoryOptions(subCategoriesData);
      } else {
        throw new Error('Subcategories could not be fetched');
      }
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    setSelectedSubCategory(null);
    setSelectedBrand(null);
    if (selectedOption) {
      fetchSubCategories(selectedOption);
      setLoading(true);
      generateToken()
        .then(token => {
          fetchProducts(token, selectedOption.value, selectedColor, selectedSize, selectedBrand)
            .then(productsData => {
              setProducts(productsData);
              setLoading(false);
            });
        })
        .catch(error => console.error('Token could not be retrieved:', error));
    }
  };

  const handleSubCategoryChange = (selectedOption) => {
    setSelectedSubCategory(selectedOption);
    setSelectedBrand(null);
    if (selectedOption) {
      setLoading(true);
      generateToken()
        .then(token => {
          fetchProducts(token, selectedOption.value, selectedColor, selectedSize, selectedBrand)
            .then(productsData => {
              setProducts(productsData);
              setLoading(false);
            });
        })
        .catch(error => console.error('Token could not be retrieved:', error));
    }
  };

  const handleColorChange = (selectedOption) => {
    setSelectedColor(selectedOption);
    applyFilters();
  };

  const handleSizeChange = (selectedOption) => {
    setSelectedSize(selectedOption);
    applyFilters();
  };

  const handleBrandChange = (selectedOption) => {
    setSelectedBrand(selectedOption);
    applyFilters();
  };

  const handleMinPriceChange = (event) => {
    setMinPrice(event.target.value);
    applyFilters();
  };

  const handleMaxPriceChange = (event) => {
    setMaxPrice(event.target.value);
    applyFilters();
  };

  const columns = [
    { id: 'id', label: 'Product Id', minWidth: 100 },
    { id: 'image', label: 'Product Image', minWidth: 100 },
    { id: 'name', label: 'Product Name', minWidth: 100 },
    { id: 'platform', label: 'Platform', minWidth: 100 },
    { id: 'brandName', label: 'Brand', minWidth: 100 },
    { id: 'color', label: 'Color', minWidth: 100 },
    { id: 'size', label: 'Sizes', minWidth: 100 },
    { id: 'price', label: 'Price', minWidth: 100 },
    { id: 'productUrl', label: 'Product Url', minWidth: 100 }
  ];

  const loadPreviousPage = () => {
    setPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const loadNextPage = () => {
    setPage(prevPage => prevPage + 1);
  };

  const handlePageInputChange = (e) => {
    const pageNum = parseInt(e.target.value, 10);
    if (!isNaN(pageNum) && pageNum > 0) {
      setPage(pageNum);
    }
  };

  const productsPerPage = 15;
  const paginatedProducts = filteredProducts.slice((page - 1) * productsPerPage, page * productsPerPage);

  return (
    <Fragment>
      <Breadcrumb title="Man" parent="Products" />
      <Container fluid={true}>
        <style jsx="true">{`
          @media (max-width: 768px) {
            .select-container {
              width: 100% !important;
              margin-bottom: 10px;
            }
            .text-field-container {
              width: 100% !important;
            }
          }
          @media (min-width: 769px) {
            .upper-selects {
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
            }
            .lower-selects {
              display: flex;
              justify-content: space-between;
            }
            .select-container {
              width: 30%;
              margin-bottom: 10px;
            }
            .text-field-container {
              display: flex;
              justify-content: space-between;
              width: 30%;
            }
          }
        `}</style>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="upper-selects">
                  <Select
                    placeholder="Select Category"
                    options={categoryOptions}
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    styles={{
                      container: (provided) => ({ ...provided, width: '100%' }),
                      menu: (provided) => ({ ...provided, zIndex: 9999 })
                    }}
                    className="select-container"
                    isClearable
                  />
                  <Select
                    placeholder="Select SubCategory"
                    options={subCategoryOptions}
                    value={selectedSubCategory}
                    onChange={handleSubCategoryChange}
                    styles={{
                      container: (provided) => ({ 
                        ...provided, 
                        width: '100%', 
                      }),
                      menu: (provided) => ({ ...provided, zIndex: 9999 })
                    }}
                    className="select-container"
                    isClearable
                    isDisabled={!selectedCategory}
                  />
                  <Select
                    placeholder="Select Brand"
                    options={brandOptions}
                    value={selectedBrand}
                    onChange={handleBrandChange}
                    styles={{
                      container: (provided) => ({ 
                        ...provided, 
                        width: '100%',
                      }),
                      menu: (provided) => ({ ...provided, zIndex: 9999 })
                    }}
                    className="select-container"
                    isClearable
                    isDisabled={!selectedCategory}
                  />
                </div>
                <div className="lower-selects">
                  <Select
                    placeholder="Select Color"
                    options={colorOptions}
                    value={selectedColor}
                    onChange={handleColorChange}
                    styles={{
                      container: (provided) => ({ ...provided, width: '100%' }),
                      menu: (provided) => ({ ...provided, zIndex: 9999 })
                    }}
                    className="select-container"
                    isClearable
                  />
                  <Select
                    placeholder="Select Size"
                    options={sizeOptions}
                    value={selectedSize}
                    onChange={handleSizeChange}
                    styles={{
                      container: (provided) => ({ ...provided, width: '100%' }),
                      menu: (provided) => ({ ...provided, zIndex: 9999 })
                    }}
                    className="select-container"
                    isClearable
                  />
                  <div className="text-field-container">
                    <TextField
                      label="Min Price"
                      variant="outlined"
                      size="small"
                      style={{ marginRight: '10px', width: 'calc(50% - 10px)' }}
                      InputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                      value={minPrice}
                      onChange={handleMinPriceChange}
                    />
                    <TextField
                      label="Max Price"
                      variant="outlined"
                      size="small"
                      style={{ width: 'calc(50% - 10px)' }}
                      InputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                      value={maxPrice}
                      onChange={handleMaxPriceChange}
                    />
                  </div>
                </div>
                <br></br>
                <div className="clearfix"></div>
                <Paper sx={{ width: '100%', overflowX: 'auto' }}>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align="center"
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {loading ? (
                        <TableRow>
                          <TableCell colSpan={columns.length} align="center">
                            <ClipLoader color="#67c7ff" loading={loading} size={50} />
                          </TableCell>
                        </TableRow>
                      ) : paginatedProducts.length > 0 ? (
                        paginatedProducts.map((product) => (
                          <TableRow key={product.id}>
                            {columns.map((column) => {
                              const value = product[column.id];
                              if (column.id === 'image') {
                                return (
                                  <TableCell key={column.id}>
                                    <img src={value} alt={product.name} style={{ width: '100px', height: 'auto' }} />
                                  </TableCell>
                                );
                              } else if (column.id === 'productUrl') {
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    <Link to={value} target="_blank">Go to product</Link>
                                  </TableCell>
                                );
                              } else if (column.id === 'price') {
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {value + ' TL'}
                                  </TableCell>
                                );
                              } else if (column.id === 'size') {
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {value.split(', ').map((size, index) => (
                                      <span
                                        key={index}
                                        style={{
                                          backgroundColor: size === selectedSize?.value ? 'lightblue' : 'inherit',
                                          padding: '2px 4px',
                                          borderRadius: '4px',
                                          display: 'inline-block',
                                          margin: '2px'
                                        }}
                                      >
                                        {size}
                                      </span>
                                    ))}
                                  </TableCell>
                                );
                              }
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={columns.length} align="center">
                            There are no products.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                  <Button onClick={loadPreviousPage} startIcon={<SkipPreviousIcon />} disabled={page === 1}>
                    {``}
                  </Button>
                  <input
                    type="number"
                    value={page}
                    onChange={handlePageInputChange}
                    style={{ width: '50px', textAlign: 'center', margin: '0 10px' }}
                  />
                  <Button onClick={loadNextPage} endIcon={<SkipNextIcon />}>
                    {``}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Category;