import React from "react";
import { Edit } from "react-feather"; // React Feather'dan Edit3 ikonunu içe aktarın

export const data = [
	{
		Coupon_Id: "1",
		Coupon_Name: "Kupon",
		Category_Type: "altSubCategory",
		Category_Name: "dress",
		Coupon_Code: " Percent10",
		Discount: "10%",
		Beginning_Date: "11.10.2023",
		Ending_Date: "21.10.2023",
		Edit:<Edit color="green" /> 

	},
	{
		Coupon_Id: "2",
		Coupon_Name: "Kupon",
		Category_Type: "altSubCategory",
		Category_Name: "dress",
		Coupon_Code: " Percent10",
		Discount: "10%",
		Beginning_Date: "11.10.2023",
		Ending_Date: "21.10.2023",
		Edit:<Edit color="green" /> 
	},
	{
		Coupon_Id: "3",
		Coupon_Name: "Kupon",
		Category_Type: "altSubCategory",
		Category_Name: "dress",
		Coupon_Code: " Percent10",
		Discount: "10%",
		Beginning_Date: "11.10.2023",
		Ending_Date: "21.10.2023",
		Edit:<Edit color="green" /> 
	},
	{
		Coupon_Id: "4",
		Coupon_Name: "Kupon",
		Category_Type: "altSubCategory",
		Category_Name: "dress",
		Coupon_Code: " Percent10",
		Discount: "10%",
		Beginning_Date: "11.10.2023",
		Ending_Date: "21.10.2023",
		Edit:<Edit color="green" /> 
	},
	{
		Coupon_Id: "5",
		Coupon_Name: "Kupon",
		Category_Type: "altSubCategory",
		Category_Name: "dress",
		Coupon_Code: " Percent10",
		Discount: "10%",
		Beginning_Date: "11.10.2023",
		Ending_Date: "21.10.2023",
		Edit:<Edit color="green" /> 
	},
	{
		Coupon_Id: "6",
		Coupon_Name: "Kupon",
		Category_Type: "altSubCategory",
		Category_Name: "dress",
		Coupon_Code: " Percent10",
		Discount: "10%",
		Beginning_Date: "11.10.2023",
		Ending_Date: "21.10.2023",
		Edit:<Edit color="green" /> 
	},
	{
		Coupon_Id: "7",
		Coupon_Name: "Kupon",
		Category_Type: "altSubCategory",
		Category_Name: "dress",
		Coupon_Code: " Percent10",
		Discount: "10%",
		Beginning_Date: "11.10.2023",
		Ending_Date: "21.10.2023",
		Edit:<Edit color="green" /> 
	},
	{
		Coupon_Id: "8",
		Coupon_Name: "Kupon",
		Category_Type: "altSubCategory",
		Category_Name: "dress",
		Coupon_Code: " Percent10",
		Discount: "10%",
		Beginning_Date: "11.10.2023",
		Ending_Date: "21.10.2023",
		Edit:<Edit color="green" /> 
	},
];

export default data;
