// LoginTabset.js
import React, { Fragment, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, Alert } from "reactstrap";
import { useAuth } from "./AuthContext";

const LoginTabset = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    const clickActive = (event) => {
        document.querySelector(".nav-link").classList.remove("show");
        event.target.classList.add("show");
    };

    const routeChange = () => {
        if (username === "sellerportal@admin.com" && password === "sellerWox123*") {
            login();
            navigate("/dashboard");
        } else {
            setError(true);
        }
    };

    return (
        <div>
            <Fragment>
                <Tabs>
                    <TabList className="nav nav-tabs tab-coupon">
                        <Tab className="nav-link" onClick={(e) => clickActive(e)}>
                            <User />
                            Login
                        </Tab>
                    </TabList>

                    <TabPanel>
                        <Form className="form-horizontal auth-form" onSubmit={(e) => e.preventDefault()}>
                            <FormGroup>
                                <Input
                                    required
                                    name="login[username]"
                                    type="email"
                                    className="form-control"
                                    placeholder="Username"
                                    id="exampleInputEmail1"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    required
                                    name="login[password]"
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </FormGroup>

                            <div className="form-button">
                                <Button
                                    color="primary"
                                    type="submit"
                                    onClick={() => routeChange()}
                                >
                                    Login
                                </Button>
                            </div>
                            {error && (
                                <Alert color="danger" className="mt-3">
                                    Incorrect username or password!
                                </Alert>
                            )}
                        </Form>
                    </TabPanel>
                </Tabs>
            </Fragment>
        </div>
    );
};

export default LoginTabset;
