export const data = [
	{
		no: "1",
		Sipariş_Tarihi: "1/1/2022",
		Tahmini_Teslim: "3.62",
		Sipariş_Tutar: "816",
		Vergi: "65.28",
		Toplam_Tutar: "884.16",
	},
	{
		no: "2",
		Sipariş_Tarihi: "1/1/2022",
		Tahmini_Teslim: "3.62",
		Sipariş_Tutar: "816",
		Vergi: "65.28",
		Toplam_Tutar: "884.16",
	},

	{
		no: "3",
		Sipariş_Tarihi: "1/1/2022",
		Tahmini_Teslim: "3.62",
		Sipariş_Tutar: "816",
		Vergi: "65.28",
		Toplam_Tutar: "884.16",
	},
	{
		no: "4",
		Sipariş_Tarihi: "1/1/2022",
		Tahmini_Teslim: "3.62",
		Sipariş_Tutar: "816",
		Vergi: "65.28",
		Toplam_Tutar: "884.16",
	},
	{
		no: "5",
		Sipariş_Tarihi: "1/1/2022",
		Tahmini_Teslim: "3.62",
		Sipariş_Tutar: "816",
		Vergi: "65.28",
		Toplam_Tutar: "884.16",
	},
	{
		no: "6",
		Sipariş_Tarihi: "1/1/2022",
		Tahmini_Teslim: "3.62",
		Sipariş_Tutar: "816",
		Vergi: "65.28",
		Toplam_Tutar: "884.16",
	},
	{
		no: "7",
		Sipariş_Tarihi: "1/1/2022",
		Tahmini_Teslim: "3.62",
		Sipariş_Tutar: "816",
		Vergi: "65.28",
		Toplam_Tutar: "884.16",
	},
	{
		no: "8",
		Sipariş_Tarihi: "1/1/2022",
		Tahmini_Teslim: "3.62",
		Sipariş_Tutar: "816",
		Vergi: "65.28",
		Toplam_Tutar: "884.16",
	},
	{
		no: "9",
		Sipariş_Tarihi: "1/1/2022",
		Tahmini_Teslim: "3.62",
		Sipariş_Tutar: "816",
		Vergi: "65.28",
		Toplam_Tutar: "884.16",
	},
	{
		no: "10",
		Sipariş_Tarihi: "1/1/2022",
		Tahmini_Teslim: "3.62",
		Sipariş_Tutar: "816",
		Vergi: "65.28",
		Toplam_Tutar: "884.16",
	},
	
];

export default data;
